import React from "react";
import AppContainer from "../AppContainer";
import { FlagsProvider } from "../orderingFlags";

const FeaturesContainer = () => {
  return (
    <FlagsProvider
      flags={{
        SSOButtonType: "simple",
        requestReference: true,
        allowedRegister: false,
        minimumOrder: false,
        hidePasswordReset: true,
        hidePrices: true,
        hideCancelOrder: true,
        hideAddresses: true,
        hasAdditionalInfo: true,
        volumeControl: { enabled: false },
        allowedCountries: [
          { code: "FR", name: "France" },
          { code: "DE", name: "Germany" },
        ],
        footers: {
          imprint: {
            markdown: false,
            links: {
              en: "https://totalenergies.com/legal",
              fr: "https://totalenergies.com/fr/mentions-legales",
            },
          },
          privacypolicy: {
            markdown: false,
            links: {
              en: "https://totalenergies.com/privacy",
              fr: "https://totalenergies.com/fr/donnees-personnelles",
            },
          },
          accessibility: {
            markdown: false,
            links: {
              en: "https://totalenergies.com/accessibility",
              fr: "https://totalenergies.com/fr/accessibilite",
            },
          },
          contact: {
            markdown: false,
            links: {
              en: "https://totalenergies.com/contact-us",
              fr: "https://totalenergies.com/fr/nous-contacter",
            },
          },
        },
        companyProfile: {
          userPanel: false,
          nonEditableAddressCategories: [],
        },
      }}
    >
      <AppContainer />
    </FlagsProvider>
  );
};

export default FeaturesContainer;
